var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLBASEINFO" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.isOld
                              ? _c("c-qr-btn", {
                                  attrs: {
                                    mobileUrl:
                                      "/sop/fft/fireFightingMaster?sopFireFightingId=" +
                                      _vm.popupParam.sopFireFightingId,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.fireFighting,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "소방설비명",
                              name: "fireFightingName",
                            },
                            model: {
                              value: _vm.fireFighting.fireFightingName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "fireFightingName",
                                  $$v
                                )
                              },
                              expression: "fireFighting.fireFightingName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "관리번호",
                              name: "manageNo",
                            },
                            model: {
                              value: _vm.fireFighting.manageNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "manageNo", $$v)
                              },
                              expression: "fireFighting.manageNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "SOP_FIRE_FIGHTING_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "sopFireFightingTypeCd",
                              label: "설비유형",
                            },
                            model: {
                              value: _vm.fireFighting.sopFireFightingTypeCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "sopFireFightingTypeCd",
                                  $$v
                                )
                              },
                              expression: "fireFighting.sopFireFightingTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              comboItems: _vm.kindItems,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "sopFireFightingKindCd",
                              label: "설비종류",
                            },
                            model: {
                              value: _vm.fireFighting.sopFireFightingKindCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "sopFireFightingKindCd",
                                  $$v
                                )
                              },
                              expression: "fireFighting.sopFireFightingKindCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBLAMOUNT",
                              type: "number",
                              name: "amount",
                            },
                            model: {
                              value: _vm.fireFighting.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "amount", $$v)
                              },
                              expression: "fireFighting.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-danger-area", {
                            attrs: {
                              editable: _vm.editable,
                              plantCd: _vm.fireFighting.plantCd,
                              label: "관련 위험물저장소",
                              name: "chmDangerMstId",
                            },
                            model: {
                              value: _vm.fireFighting.chmDangerMstId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "chmDangerMstId",
                                  $$v
                                )
                              },
                              expression: "fireFighting.chmDangerMstId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              disabled: _vm.isOld,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.fireFighting.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "plantCd", $$v)
                              },
                              expression: "fireFighting.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              editable: _vm.editable,
                              parentCheckDepts: _vm.fireFighting.deptCd,
                              plantCd: _vm.fireFighting.plantCd,
                              label: "관리부서",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.fireFighting.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "deptCd", $$v)
                              },
                              expression: "fireFighting.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "관련공정",
                              multiple: "multiple",
                              name: "processCd",
                            },
                            model: {
                              value: _vm.fireFighting.processCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "processCd", $$v)
                              },
                              expression: "fireFighting.processCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설치위치",
                              name: "location",
                            },
                            model: {
                              value: _vm.fireFighting.location,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "location", $$v)
                              },
                              expression: "fireFighting.location",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설치일",
                              name: "installDate",
                            },
                            model: {
                              value: _vm.fireFighting.installDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "installDate", $$v)
                              },
                              expression: "fireFighting.installDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치상태 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              comboItems: _vm.disposeFlagItems,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "disposeFlag",
                              label: "폐기여부",
                            },
                            model: {
                              value: _vm.fireFighting.disposeFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "disposeFlag", $$v)
                              },
                              expression: "fireFighting.disposeFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: _vm.fireFighting.disposeFlag === "Y",
                              editable: _vm.editable,
                              disabled: _vm.fireFighting.disposeFlag === "N",
                              label: "폐기일",
                              name: "disposeDate",
                            },
                            model: {
                              value: _vm.fireFighting.disposeDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "disposeDate", $$v)
                              },
                              expression: "fireFighting.disposeDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _vm.isOld
            ? _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "교체이력",
                    gridHeight: _vm.setheight,
                    columns: _vm.grid.columns,
                    data: _vm.fireFighting.replacementHistroies,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: _vm.editable,
                    isExcelDown: false,
                    isFullScreen: false,
                    selection: "multiple",
                    rowKey: "sopFireFightingReplacementHistoryId",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLADD",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addReplacementHistory },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "제외", icon: "remove" },
                                on: {
                                  btnClicked: _vm.deleteReplacementHistory,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }